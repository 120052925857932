import React from "react";
import * as styles from "./disclaimer.module.css";

const Disclaimer = () => {
  return (
    <>
      <div className={styles.mainSection}>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <h1>Disclaimer Sleepless Yogi</h1>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <p>Hi,</p>
            <p>
              If you require any more information or have any questions about
              our site’s disclaimer, please feel free to contact us by email at
              sleeplessyogi@gmail.com. Our Disclaimer was generated with the
              help of the{" "}
              <a
                href="https://www.termsfeed.com/disclaimer-generator/"
                alt="Disclaimer Generator"
              >
                Disclaimer Generator
              </a>
              .
            </p>
            <p>
              All the information on this website – sleeplessyogi.com – is
              published in good faith and for general information purposes only.
              sleeplessyogi does not make any warranties about the completeness,
              reliability, and accuracy of this information. Any action you take
              upon the information you find on this website (sleeplessyogi), is
              strictly at your own risk. sleeplessyogi will not be liable for
              any losses and/or damages in connection with the use of our
              website.
            </p>
            <p>
              From our website, you can visit other websites by following
              hyperlinks to such external sites. While we strive to provide only
              quality links to useful and ethical websites, we have no control
              over the content and nature of these sites. These links to other
              websites do not imply a recommendation for all the content found
              on these sites. Site owners and content may change without notice
              and may occur before we have the opportunity to remove a link that
              may have gone ‘bad’.
            </p>
            <p>
              Please be also aware that when you leave our website, other sites
              may have different privacy policies and terms that are beyond our
              control. Please be sure to check the Privacy Policies of these
              sites as well as their “Terms of Service” before engaging in any
              business or uploading any information.
            </p>
            <h2>Consent</h2>
            <p>
              By using our website, you hereby consent to our disclaimer and
              agree to its terms.
            </p>
            <h2>Update</h2>
            <p>
              Should we update, amend or make any changes to this document,
              those changes will be prominently posted here.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Disclaimer;
