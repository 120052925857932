import React from "react";
import Index from "../components/disclaimer";

const disclaimer = () => {
  return (
    <>
      <Index />
    </>
  );
};

export default disclaimer;
